import React, { ReactNode, useEffect, useState } from 'react';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Analytics, getAnalytics } from 'firebase/analytics';

import { ctx } from './context';

interface FirebaseProviderProps {
  children: ReactNode;
  config: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
};

export function FirebaseProvider({ children, config }: FirebaseProviderProps) {
  const [app, setApp] = useState<FirebaseApp>();
  const [analytics, setAnalytics] = useState<Analytics>();

  useEffect(() => {
    setApp(initializeApp(config));
  }, [config]);

  useEffect(() => {
    setAnalytics(getAnalytics(app));
  }, [app]);

  return (
    <ctx.Provider value={{ app, analytics }}>
      {children}
    </ctx.Provider>
  );
};
