import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { FirebaseProvider } from './providers/firebase';

import '@fontsource/open-sans';
import '@fontsource/gothic-a1';
import './index.scss';

const firebaseConfig = {
  apiKey: 'AIzaSyDYn9WwV5-vnv0YOZ9miV2tLV4ZqlkL31w',
  authDomain: 'lucianataminato-4d967.firebaseapp.com',
  projectId: 'lucianataminato-4d967',
  storageBucket: 'lucianataminato-4d967.appspot.com',
  messagingSenderId: '192488738369',
  appId: '1:192488738369:web:4254b03d07fe8c705040c0',
  measurementId: 'G-LPGKER6WNC',
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <FirebaseProvider config={firebaseConfig}>
      <ConfigProvider theme={{
        token: {
          colorPrimaryBg: '#fff',
          colorPrimary: '#333',
          fontFamily: 'Open Sans',
        },
      }}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </FirebaseProvider>
  </React.StrictMode>
);
