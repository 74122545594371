import React, { useCallback, useState } from 'react';
import { Menu as DSMenu, MenuProps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import './styles.scss';

const items: MenuProps['items'] = [
  {
    label: 'Início',
    key: '',
  },
  {
    label: 'Sobre',
    key: 'about',
  },
  {
    label: 'Faça um orçamento',
    key: 'budget',
  },
];

export function Menu() {
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState(location.pathname.replace('/', ''));

  const handleClick = useCallback((e: any) => {
    if (e.key === 'budget') {
      window.open('https://api.whatsapp.com/send?phone=5519991363334', '_blank', 'noreferrer');
      return;
    }

    setCurrent(e.key);
    navigate(`/${e.key}`);
  }, [navigate]);

  return (
    <DSMenu
      onClick={handleClick}
      selectedKeys={[current]}
      mode="horizontal"
      items={items}
    />
  );
};
