import React from 'react';
import { Layout, Typography } from 'antd';
import { Route, Routes } from 'react-router-dom';

import { Menu } from './components/menu';
import { SocialMedias } from './components/social';
import { Logo } from './components/logo';

import { HomePage } from './pages/home';
import { AboutPage } from './pages/about';
import { NotFoundPage } from './pages/not-found';

import './App.scss';

const { Header, Content, Footer } = Layout;
const { Text } = Typography;

function App() {
  return (
    <Layout className="App">
      <Header className="App__header">
        <Logo />
        <Menu />
        <SocialMedias />
      </Header>
      <Content className="App__content">
        <Routes>
          <Route path="/" element={<HomePage />} key="home" />
          <Route path="/about" element={<AboutPage />} key="about" />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Content>
      <Footer className="App__footer">
        <Text>Copyright 2024 · All rights reserved.</Text>
      </Footer>
    </Layout>
  );
}

export default App;
