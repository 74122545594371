import React from 'react';
import { Space, Typography } from 'antd';

import './styles.scss';

const { Title, Text } = Typography;

export function AboutPage() {
  return (
    <div className="About">
      <Title level={2}>Sobre mim</Title>
      <div className="About__story">
        <Space size="large" direction="vertical">
          <Text>Página em construção.</Text>
        </Space>
      </div>
    </div>
  );
};
