import React, { useCallback } from 'react';
import { Button, Space } from 'antd';
import { InstagramFilled, WhatsAppOutlined } from '@ant-design/icons';

enum SocialNetwork {
  INSTAGRAM = 'INSTAGRAM',
  WHATSAPP = 'WHATSAPP',
}

const titles: Record<SocialNetwork, string> = {
  [SocialNetwork.INSTAGRAM]: 'Instagram',
  [SocialNetwork.WHATSAPP]: 'WhatsApp',
};

const urls: Record<SocialNetwork, string> = {
  [SocialNetwork.INSTAGRAM]: 'https://www.instagram.com/lucianataminato_personalizados/',
  [SocialNetwork.WHATSAPP]: 'https://api.whatsapp.com/send?phone=5519991363334',
};

const icons: Record<SocialNetwork, JSX.Element> = {
  [SocialNetwork.INSTAGRAM]: <InstagramFilled style={{ color: '#333' }} />,
  [SocialNetwork.WHATSAPP]: <WhatsAppOutlined style={{ color: '#333' }} />,
};

export function SocialMedias() {
  const handleClick = useCallback((network: SocialNetwork) => () => {
    window.open(urls[network], '_blank', 'noreferrer');
  }, []);

  return (
    <div className="SocialMedias">
      <Space size="small">
        {Object.keys(SocialNetwork).map((social) => (
          <Button
            key={social}
            icon={icons[social as SocialNetwork]}
            type="link"
            onClick={handleClick(social as SocialNetwork)}
            title={titles[social as SocialNetwork]}
          />
        ))}
      </Space>
    </div>
  );
};
