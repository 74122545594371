import { createContext, useContext } from 'react';
import { FirebaseApp } from 'firebase/app';
import { Analytics } from 'firebase/analytics';

interface FirebaseState {
  app?: FirebaseApp;
  analytics?: Analytics;
}

export const ctx = createContext<FirebaseState>({});

export function useFirebase() {
  const { app } = useContext(ctx);
  return app;
};

export function useAnalytics() {
  const { analytics } = useContext(ctx);
  return analytics;
};
