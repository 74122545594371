import React from 'react';
import { Card, Image } from 'antd';

const { Meta } = Card;

interface GalleryType {
  title: string;
  cover: string;
  pictures: string[];
};

export function Gallery({ title, cover, pictures}: GalleryType) {
  return (
    <Card
      hoverable
      cover={(
        <Image.PreviewGroup
          items={pictures}
        >
          <Image
            style={{ maxWidth: '300px' }}
            alt={title}
            src={cover}
            preview={{ mask: false }}
          />
        </Image.PreviewGroup>
      )}
    >
      <Meta title={title} description={`${pictures.length} fotos`} />
    </Card>
  );
};
