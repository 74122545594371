import React, { useCallback } from 'react';
import { Button, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import './styles.scss';

const { Title } = Typography;

export function NotFoundPage() {
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <div className="NotFound">
      <Space direction="vertical" size="large">
        <Title level={2}>Página não encontrada</Title>
        <Title level={5}>Algum problema aconteceu e não conseguimos encontrar a página que você estava procurando. Tente voltar à página anterior ou me contate via WhatsApp.</Title>
        <Button onClick={handleClick}>Voltar ao início</Button>
      </Space>
    </div>
  );
};
