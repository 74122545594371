import React from 'react';
import { Flex, Space } from 'antd';

import './styles.scss';
import { Gallery } from '../../components/gallery';

const galleries = [
  {
    title: 'Lembrancinhas',
    type: 'lembrancinhas',
    cover: 10,
    photos: 30,
  },
  {
    title: 'Topos de bolo',
    type: 'topos-de-bolo',
    cover: 1,
    photos: 19,
  },
  {
    title: 'Etiquetas',
    type: 'etiquetas',
    cover: 2,
    photos: 11,
  },
];

export function HomePage() {
  return (
    <div className="Home">
      <Space direction="vertical" size="large" align="center">
        <img
          src="/balloons.png"
          alt="Luciana Taminato Personalizados"
          title="Luciana Taminato Personalizados"
          style={{ maxHeight: '15rem' }}
        />
        <Flex gap="large" wrap="wrap" justify="center">
          {galleries.map(gallery => {
            const pictures = Array.from(Array(gallery.photos).keys()).map(picture => `/photos/${gallery.type}/${picture + 1}.jpeg`);

            return (
              <Gallery key={gallery.type} title={gallery.title} cover={`/photos/${gallery.type}/${gallery.cover}.jpeg`} pictures={pictures} />
            )
          })}
        </Flex>
      </Space>
    </div>
  );
};
