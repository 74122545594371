import React from 'react';
import { Typography } from 'antd';

import './styles.scss';

const { Text } = Typography;


export function Logo() {
  return (
    <div className="Logo">
      <Text className="Logo__title">Luciana Taminato</Text>
      <Text className="Logo__description">Papelaria Personalizada</Text>
    </div>
  );
};
